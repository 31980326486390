import * as React from 'react';
import styled from 'styled-components';
import SimpleButton from '../components/common/SimpleButton';
import LayoutCt from '../components/layout';

// styles
const Layout = styled(LayoutCt)`
    background-image: url('/images/404/mobile/bg-404.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    .contentWrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .content {
            grid-column: 2/3;
            text-align: center;
            margin-top: 50%;
            padding: 0 6%;
            .btn {
                margin-top: 30px;
            }
        }
    }
    @media screen and (min-width: 800px) {
        background-image: url('/images/404/desktop/bg-404.jpg');
        .contentWrapper {
            height: 85%;
            .content {
                width: 50%;
                margin: 5% auto 0 auto;
                padding: 0;
            }
        }
    }
`;

// markup
const NotFoundPage = ({ pageContext }) => {
    const title =
        pageContext.lang === 'FR'
            ? 'Désolé, la page que vous recherchez n’existe pas.'
            : 'Sorry, the page you are looking for does not exist.';
    const btn =
        pageContext.lang === 'FR'
            ? { label: 'voir le site', url: '/fr' }
            : { label: 'See the site', url: '/' };
    return (
        <Layout lang={pageContext.lang}>
            <div className="content">
                <h1 className="title-1">{title}</h1>
                <SimpleButton data={btn} className="btn" />
            </div>
        </Layout>
    );
};

export default NotFoundPage;
